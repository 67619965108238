@import '../../../../scss/theme-bootstrap';

// new for 2018 Creative Refresh
.sku-list-sizes {
  &__list {
    display: flex;
    width: 100%;
  }
  &__item {
    padding-right: 0.5em;
    &:last-child {
      padding-right: 0;
    }
  }
  &__button {
    border: 1px solid $color-cr18-black;
    box-shadow: inset 0 0 0 2px $color-white;
    color: $color-cr18-black;
    display: block;
    font-size: 15px;
    font-family: $font--futura-book;
    height: 30px;
    line-height: 30px;
    padding: 0 11px;
    white-space: nowrap;
    &:hover {
      border: 1px solid $color-green;
      color: $color-green;
    }
    &.active {
      box-shadow: inset 0 0 0 2px $color-cr18-black;
      text-decoration: none;
    }
    &.button--disabled {
      text-decoration: line-through;
      cursor: default;
      pointer-events: none;
    }
  }
  &__multiple-sizes {
    .selectBox-label {
      text-overflow: ellipsis;
      min-width: 60px;
      text-transform: capitalize;
    }
    .dropdown--disabled {
      opacity: 1;
      pointer-events: auto;
      .selectBox-label {
        text-decoration: line-through;
        background: none;
        box-shadow: none;
      }
    }
    .selectbox--new {
      &.selectBox {
        min-width: 122px;
        &.dropdown--disabled {
          text-decoration: line-through;
        }
      }
      span.sku-list-sizes__button-dropdown {
        &.button--disabled {
          text-decoration: line-through;
          background-color: transparent;
          box-shadow: none;
        }
      }
    }
  }
}
